// Döviz Kuru

<template>
  <div class="daily-info two" v-if="model">
    <div class="item">
      <div class="item-text">
        <strong>Dolar</strong>
        <span>{{ model.usdValue }}</span>
      </div>
      <div class="image">
        <img class="effect3" :src="usdStatus" />
      </div>
      <!-- <div class="turn down">
        <img src="/images/currency/up.png" />
      </div>-->
      <div class="image fixed-image">
        <img class="effect3" src="/images/currency/dollar.png" />
      </div>
    </div>

    <div class="item">
      <div class="item-text">
        <strong>Euro</strong>
        <span>{{ model.euroValue }}</span>
      </div>
      <div class="image">
        <img class="effect3" :src="euroStatus" />
      </div>
      <!-- <div class="turn down">
        <img src="/images/currency/down.png" />
      </div>-->
      <div class="image fixed-image">
        <img class="effect3" src="/images/currency/euro.png" />
      </div>
    </div>

    <div class="item">
      <div class="item-text">
        <strong>Altın</strong>
        <span>{{ model.goldValue }}</span>
      </div>
      <div class="image">
        <img class="effect3" :src="goldStatus" />
      </div>
      <!-- <div class="turn down">
        <img src="/images/currency/down.png" />
      </div>-->
      <div class="image fixed-image">
        <img class="effect3" src="/images/currency/gold.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'currency2',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state.currency;
    },
    usdStatus() {
      return `/images/currency/${this.model.usdStatus}.png`;
    },
    euroStatus() {
      return `/images/currency/${this.model.euroStatus}.png`;
    },
    goldStatus() {
      return `/images/currency/${this.model.goldStatus}.png`;
    }
  }
};
</script>

<style lang="scss" scoped></style>
